import select from 'select-dom'
import on from 'dom-event'
import { kebabToCamel, isFunction } from './util'

/**
 * Initializes JS modules within a DOM element
 *
 * @param {object} modules - Modules to initialize
 * @param {Element} parent - DOM element where modules are contained
 */
function initializer (modules = {}, parent = document) {
  const moduleElements = select.all('[data-module]', parent)
  const initModule = el => init(modules[kebabToCamel(el.dataset.module)], el)

  moduleElements.forEach(initModule)

  if (window.Shopify.designMode) {
    on(document, 'shopify:section:load', (evt) => {
      const moduleElements = select.all('[data-module]', evt.target)

      moduleElements.forEach(initModule)
    })
  }
}

/**
 * Initializes a module on a DOM element
 *
 * @param {Function} module - Module to initialize
 * @param {Element} el - Element to initialize the module
 */
function init (module, el) {
  if (!module) return

  if (typeof module.then === 'function') {
    module.then(({ default: asyncModule }) => {
      if (!isFunction(asyncModule)) return

      asyncModule(el)
    })
    return
  }

  if (!isFunction(module)) return

  module(el)
}

export default initializer
