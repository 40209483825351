import inViewport from 'in-viewport'
import select from 'select-dom'
import { camelToKebab } from './util'

/**
 * Lazy loads a Module
 *
 * @param {string} module - Selector that triggers the load of the module
 * @param {object} options - Options for in-viewport
 * @returns {Promise} Resolves with a dynamic import
 */
export default (module, options = {}) => new Promise(resolve => {
  const moduleName = camelToKebab(module)
  const element = select(`[data-module="${moduleName}"]`)

  inViewport(element, options, () => resolve(
    import(
      /* webpackMode: "lazy-once" */
      `~/modules/${moduleName}/${moduleName}.js`
    )
  ))
})
